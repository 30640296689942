import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import CustomSnackbar from 'components/chat/CustomSnackbar';
import { t } from 'i18next';
import { deleteFiles, fetchUploadingStatus } from 'model/services/files/fetchFiles';
import { SessionFileInfo } from 'model/services/globalUpload/types';
import { filesAction } from 'model/slices/filesSlice';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TStore } from 'store';

interface UploadedFilesProps {}

const SessionUploadedFiles: React.FC<UploadedFilesProps> = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [fileProgress, setFileProgress] = useState<{ [fileName: string]: number }>({});
  const [isErrorOnUpload, setIsErrorOnUpload] = useState<boolean>(false);
  const isUploadingFile = useSelector((state: TStore) => state.uploadedFilesReducer.isUploading);
  const isFileAttachedFromNewChat = useSelector(
    (state: TStore) => state.uploadedFilesReducer.isFileAttachedFromNewChat,
  );
  const fileInfos = useSelector((state: TStore) => state.uploadedFilesReducer.uploadedFiles);
  const activeConversation = useSelector((state: TStore) => state.conversationsReducer.activeConversation);
  const uploadingFiles = useSelector((state: TStore) => state.uploadedFilesReducer.uploadingFiles);
  const dispatch = useDispatch();

  if (!activeConversation?.sessionId) return null;

  useEffect(() => {
    const fetchProgress = async () => {
      const newFileProgress: { [fileName: string]: number } = {};
      for (const fileInfo of fileInfos) {
        if (!fileProgress[fileInfo.name] || fileProgress[fileInfo.name] < 100) {
          const progress = await fetchUploadingStatus(dispatch, fileInfo.name);
          newFileProgress[fileInfo.name] = progress;
        }

        if (fileProgress[fileInfo.name] === 100) {
          setTimeout(() => {
            dispatch(filesAction.setUploadingState(false));
          }, 1000);
        }
      }
      setFileProgress(prevProgress => ({ ...prevProgress, ...newFileProgress }));
    };

    if (isFileAttachedFromNewChat) {
      const timer = setInterval(fetchProgress, 2500);

      return () => {
        clearInterval(timer);
      };
    }
  }, [fileInfos, fileProgress, dispatch, isFileAttachedFromNewChat]);

  const handleFileDeleteClick = async (fileinfo: SessionFileInfo) => {
    const response = await deleteFiles({ dispatch, data: [fileinfo.hash], sessionId: activeConversation?.sessionId });
    if (response) {
      dispatch(filesAction.removeUploadedFiles(fileinfo.hash));
    } else {
      setSnackbarOpen(true);
      setSnackbarMessage('magenta_gateway/session_files');
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setIsErrorOnUpload(false);
  };

  return (
    <>
      <div className="w-full rounded-lg p-2">
        <div className="flex flex-wrap gap-4">
          {fileInfos.map((fileInfo, index) => {
            const isFileUploading = uploadingFiles.includes(fileInfo.name);
            const progress = fileProgress[fileInfo.name] || 0;
            return (
              <div className="flex flex-shrink-0 flex-col" key={index}>
                <div className="align-center flex justify-between gap-2 rounded px-1 py-[2px] hover:bg-disable">
                  <span
                    className={`text-ellipsis whitespace-nowrap text-[12px] leading-5 ${
                      isErrorOnUpload ? 'text-[#B01C2A]' : 'text-[#202020]'
                    }`}
                  >
                    {fileInfo.name}
                  </span>
                  <IconButton
                    className="h-[20px] w-[20px] p-0 text-[#202020]"
                    aria-label="delete"
                    onClick={() => handleFileDeleteClick(fileInfo)}
                    size="small"
                    disabled={isFileUploading}
                    id="deleteSessionFile"
                  >
                    <ClearIcon fontSize="inherit" />
                  </IconButton>
                </div>

                {isUploadingFile && (
                  <>
                    <div
                      className={`${
                        isErrorOnUpload ? 'text-[#B01C2A]' : 'text-[#7c7c7c]'
                      } flex items-start justify-start gap-5
                    px-1 text-[10px]`}
                    >
                      <span>{fileInfo.size}</span>
                      <span>{isErrorOnUpload ? t('Upload Failed') : t('Loading')}</span>
                    </div>

                    <div className="px-1">
                      <Box
                        sx={{ width: '100%' }}
                        className={`rounded ${isErrorOnUpload ? 'text-[#B01C2A]' : 'text-magenta'}`}
                      >
                        <LinearProgress color="inherit" variant="determinate" value={isErrorOnUpload ? 65 : progress} />
                      </Box>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <CustomSnackbar open={snackbarOpen} message={snackbarMessage} onClose={handleSnackbarClose} />
    </>
  );
};

export default SessionUploadedFiles;
